	/*
  	Flaticon icon font: Flaticon
  	Creation date: 22/04/2020 09:56
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
font-size: 20px;
font-style: normal;
}

.flaticon-calendar:before { content: "\f100"; }
.flaticon-calendar-1:before { content: "\f101"; }
.flaticon-search:before { content: "\f102"; }
.flaticon-group:before { content: "\f103"; font-size: 1.5rem !important; }
.flaticon-home:before { content: "\f104"; }
.flaticon-office-building:before { content: "\f105"; }
.flaticon-globe:before { content: "\f106"; }
.flaticon-worldwide:before { content: "\f107"; }