.bookingIdText {
    font-size: 12px;
}

@media screen and (min-width: 900px) {
    .bookingIdText {
        margin-top: 20px;
        font-size: 17px;
    }

    .bookingContainerTY {
        margin-left: 100px;
    }

    .TYCont {
        width: 86% !important;
        padding-top: 20px !important;
        padding-left: 20px !important;
        margin-bottom: 30px;
    }
}