@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Chorasmian&family=Roboto:ital,wght@0,500;1,900&display=swap");

.main {
  background-color: rgba(125, 125, 125, 0.51);
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1000;
  top: 0;
}
:root {
  --input-border: #5abab6;
  --input-focus-h: 245;
  --input-focus-s: 100%;
  --input-focus-l: 42%;
}
.hbl_popup {
  height: fit-content;
  width: fit-content;
  position: absolute;
  top: 50%;
  left: 50%;
  animation: fadeIn 3s;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-animation: fadeIn 3s;
}
img.payment_logo {
  max-width: 5.5rem;
}
.color_primary {
  color: var(--primary-color);
}
.payment_summary {
  background-color: #ffffff;
  padding: 0.7em 1.2em;
  margin-top: 20px;
  box-shadow: 1px 2px 2px 2px rgba(125, 125, 125, 0.51);
}
.hbl_popup {
  background-color: #ffffff;
  height: fit-content;
  width: 500px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.cursor {
  cursor: pointer;
  color: #808080;
  font-weight: bolder;
}
.hbl_popup-header {
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  color: #272c2f;
  padding: 0.6em 1.5em;
}
.payment_grid {
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  color: #272c2f;
}
.hbl_popup-header h2 {
  font-size: 1rem;
  font-weight: bold;
}
.hbl_popup-body {
  background-color: #efefef;
  padding: 1em 1.5em;
}
.p_m_2 {
  margin-bottom: 0.3rem;
}
.p_m_3 {
  margin-top: 0.5rem;
}
.payment_inner_summary {
  background-color: #efefef;
  padding: 0.8em;
  font-size: 0.8rem;
}
.p_terms {
  font-size: 0.7rem;
  cursor: pointer;
  color: #000;
  text-decoration: underline !important;
}
.red{
  color: #DE4755;
}

.payment_proceed {
  display: block;
  width: -webkit-fill-available;
  background: var(--primary-color);
  color: #ffff;
  outline: none;
  border: 1px solid var(--primary-color);
  padding: 0.4rem;
  border-radius: 5px;
}

.payment_proceed:disabled,
.payment_proceed[disabled]{
opacity: 0.5;
}
.otp_input::placeholder,
.otp_input{
  text-align: center;
}

.resendSms{
  color: #1888FF;
}
input#text-input::placeholder {
  font-size: 0.8rem;
  font-style: italic;
  color: #a5a5a5;
}
input#text-input {
  width: -webkit-fill-available;
  padding: 0.2em 0.4em;
  font-size: 0.8rem;
  color: #a5a5a5;
  border-color: var(--primary-color);
  border-width: 0.15em;
  border-radius: 5px;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  outline: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  
}
.font_8 {
  font-size: 0.85rem;
}
.font_6 {
  font-size: 0.65rem;
}
.note {
  font-size: 0.75rem;
  color: #000;
  font-weight: bold;
  font-style: italic;
  text-decoration: underline;
}
.note_des {
  color: #a5a5a5;
  font-style: italic;
}
@media (min-width: 300px) and (max-width: 480px) {
  .hbl_popup {
    width: 320px;
  }
}
